.slickSlider .slider-2 .slick-slide div:first-child {
  /* width: 112px !important; */
  margin-right: 7px !important;
}

/* .slickSlider .slider-2 .slick-slide:first-child img {
  border-bottom-left-radius: 24px !important;
} */

.slickSlider .slider-2 .slick-slide.slick-current img {
  border: 4px solid #6764a1 !important;
  border-radius: 14px;
}

.showFullScreen .slider-2 .slick-slide.slick-current img {
  border: 4px solid #eaeaff !important;
  border-radius: 14px;
}

.activePlan .slider-2 .slick-slide.slick-current img {
  border: none !important;
  border-radius: 0px;
}

.slickSlider .slider-2.slick-slider {
  margin-left: -0px !important;
  margin-right: -6px !important;
}

.minus-6-slider .slick-slider {
  margin-left: -6px !important;
  margin-right: -6px !important;
}

.minus-4-slider .slick-slider {
  margin-left: -4px !important;
  margin-right: -4px !important;
}
