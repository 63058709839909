html,
body
  @apply bg-gray-50 font-body

// For Legacy Pages
nav.legacy
  box-shadow: 0.0625rem 0.5rem 0.5rem #e5e5e5

// /blogs
.blog-content
  p
    @apply py-4
  a
    @apply text-bluegray-300
  h2
    @apply text-xl font-bold py-3
  h3
    @apply text-xl font-bold py-2
  blockquote
    @apply py-3 px-6 my-4 bg-light-30

input:focus
  &.focus-no-shadow
    box-shadow: none

@keyframes slide-up
  0%
    transform: translateY(100%)
  100%
    transform: translateY(0)

@keyframes slide-down
  0%
    transform: translateY(0)
  100%
    transform: translateY(150%)

.slide-up
  animation: slide-up cubic-bezier(0.4, 0, 0.2, 1) 500ms

.slide-down
  animation: slide-down 0.3s 10ms forwards

@keyframes slide-left
  0%
    transform: translateX(100%)
  100%
    transform: translateX(0)

@keyframes slide-right
  0%
    transform: translateX(0)
  100%
    transform: translateX(100%)

.slide-left
  animation: slide-left cubic-bezier(0.4, 0, 0.2, 1) 300ms

.slide-right
  animation: slide-right 0.3s 10ms forwards

.bounce-in-right
  animation: bounce-in-right 0.75s ease

@keyframes bounce-in-right
  0%
    opacity: 0
    transform: translateX(2000px)

  60%
    opacity: 1
    transform: translateX(-30px)

  80%
    transform: translateX(10px)
  100%
    transform: translateX(0)

.bounce-in-left
  animation: bounce-in-left 0.6s ease

@keyframes bounce-in-left
  0%
    opacity: 0
    transform: translateX(-2000px)

  60%
    opacity: 1
    transform: translateX(30px)

  80%
    transform: translateX(-10px)
  100%
    transform: translateX(0)

.tooltip-inline
  display: inline-flex !important
  margin: 0 !important

.react-toggle--checked .react-toggle-track
  background-color: #050035 !important

.react-toggle--checked .react-toggle-thumb
  left: 27px !important
  border-color: #9593BD !important

.react-toggle-track
  background-color: #C1C7D0 !important

.referral-card
  background: rgb(134,106,240)
  background: linear-gradient(90deg, rgba(134,106,240,1) 0%, rgba(113,164,240,1) 100%)
  border: 0

.application-input
  @apply flex flex-col space-y-1 my-4
  &.required
    label:after
      content: '*'
      @apply text-red-400
  &.required-before
    label:before
      content: '*'
      @apply text-red-400
  label
    @apply text-dark-600 md:text-dark-900 font-semibold
  input
    @apply border border-light-40 rounded-full px-4 h-9 text-mid-300 w-full bg-white focus:border-inherit focus:ring-0
    &:disabled
      @apply bg-light-20 opacity-50
  textarea
    @apply border border-light-40 rounded-lg px-4 text-mid-300 w-full
  button
    @apply bg-bluegray-300 text-white rounded-full px-4 h-9
    &:disabled
      @apply opacity-50

h5.application-subheading
  @apply text-center text-lg font-semibold leading-7 text-lilac-500 md:text-left

.custom-date-picker
  .react-datepicker
    @apply rounded-xl border border-gray-200 overflow-hidden px-2 pt-2 shadow-md font-body
  .react-datepicker__header
    @apply bg-white border-0 p-0
  .react-datepicker__day
    @apply text-dark-900
  .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__month--selected, .react-datepicker__month-text--keyboard-selected
    @apply bg-bluegray-300 text-white
  .react-datepicker__day--disabled
    @apply text-light-40
  .react-datepicker__month-wrapper
    @apply space-x-0 flex
  .react-datepicker__month .react-datepicker__month-text
    @apply m-0.5 w-20 p-0.5

.hide-tooltip-arrow
  &:before
    visibility: hidden !important
  &:after
    visibility: hidden !important

.custom-slider-track-1
  @apply h-1 bg-dark-900

.hide-scrollbar
  scrollbar-width: none
  -ms-overflow-style: -ms-autohiding-scrollbar
  &::-webkit-scrollbar
    display: none
