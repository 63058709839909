.chatlio-closed {
  z-index: 49 !important;
}

#chatlio-widget-container {
  bottom: 65px;
}

@media (max-width: 480px) {
  .chatlio-widget-body {
    height: calc(100vh - 160px) !important;
  }
}

@media (min-width: 768px) {
  #chatlio-widget-container {
    bottom: 75px;
  }
}

#chatlio-message-container {
  overflow-y: auto;
}

.hide-chatlio-menu .chatlio-chat-header-menu {
  display: none;
}
