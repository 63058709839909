@tailwind base

@import 'custom.sass'

@tailwind components

@tailwind utilities

@layer components
  .action-card
    @apply border-2 border-transparent shadow-lg hover:border-dark-900 relative rounded-3xl p-6 m-1 my-2 overflow-hidden relative z-0

  .primary-button
    @apply bg-bluegray-300 rounded-3xl text-white text-center px-4 py-1 font-light w-full
